<template>
    <div class="account-bookings container py-5">
        <div class="card mb-4"
             v-for="order in orders.slice().reverse()"
             v-bind:key="order._id">
            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <p class="mb-0">
                                <strong>ORDER PLACED</strong>
                            </p>
                            <p class="mb-1">{{ order.createdAt | dateFnsFormatter("yyyy/M/d") }}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="mb-0">
                                <strong>TOTAL</strong>
                            </p>
                            <p class="mb-1">HK${{ order.items.reduce((acc, item) => acc += item.skus.reduce((acc, sku) => acc += sku.hkd, 0), 0) }}</p>
                        </div>
                        <div class="col-md-6 text-md-right">
                            <p class="mb-0">
                                <strong>ORDER # {{ order._id }}</strong>
                            </p>
                            <p class="mb-1">Details | Invoice</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="container">
                    <div class="row"
                         v-for="item in order.items"
                         v-bind:key="item._id">
                        <div class="col-lg-9">
                            <ul class="list-unstyled">
                                <li class="media py-3 align-items-stretch">
                                    <div class="media-body">
                                        <h4 class="mt-0 mb-1"
                                            v-html="item.title[$i18n.locale]">
                                        </h4>
                                        <div class="mt-2 mb-2">
                                            <p class="d-inline-block mb-0">
                                                {{ $t("message.basket.treatments.options") }}
                                            </p>
                                            <h5 class="d-inline-block mb-0">
                                                <span class="badge badge-dark font-weight-light mr-2"
                                                      v-for="sku in item.skus"
                                                      v-bind:key="sku._id">
                                                    {{ sku.name[$i18n.locale] }}
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="mb-2" v-if="item.branch">
                                            <p class="mb-0">
                                                <span>
                                                    {{ $t("message.basket.treatments.branch") }}
                                                </span>
                                                <span class="font-weight-bold">
                                                    {{ item.branch.address[$i18n.locale] }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="mb-1 mb-md-0">
                                            <h5 class="mb-0">
                                                <small>HK$</small>{{ item.skus.reduce((acc, sku) => acc += sku.hkd, 0) | currency("", 0) }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-3">
                            <div class="booking-date d-flex flex-column justify-content-center p-3">
                                <p class="text-center mb-0">
                                    {{ $t("message.basket.treatments.date") }}
                                </p>
                                <h1 class="text-center mb-0">{{ item.date | dateFnsFormatter("d MMM") }}</h1>
                                <h4 class="text-center">{{ item.date | dateFnsFormatter("yyyy") }}</h4>
                                <h5 class="text-center font-weight-normal mb-0">
                                    {{ item.session }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountBookings",
        metaInfo () {
            return {
                title: this.$t("message.account.bookings"),
                meta: [{
                    "name": "description",
                    "content": this.$t("message.account.bookings"),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$t("message.account.bookings"),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$t("message.account.bookings"),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "website",
                    "vmid": "og:type"
                }]
            }
        },
        data () {
            return {

            }
        },
        computed: {
            orders () {
                return this.$store.state.mall.orders.salon;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .account-bookings {
        color: rgba(113, 113, 113, 1);

        .item-image-wrapper {
            min-width: 120px;

            & > .item-image {
                height: 150px;
                max-width: 100%;
            }
        }

        .booking-date {
            border: solid 1px rgba(205, 205, 205, 1);
            border-radius: 10px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
        }
    }
</style>
